<template>
	<div>
        <div class="header-content">
            <h2 class="cs-title" v-if="data.title">
                <ColorSplit :data="data.title" />
            </h2>
            <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
            <span class="cs-text" v-if="data.text" v-html="data.text"></span>
        </div>
        <PostCards :posts="data.posts" :readMoreLabel="data.readMoreLabel" />
    </div>
</template>

<script>
	export default {
		name: "CSBlogPostArchive",
		components: {
			ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
			PostCards: () => import("../components/CSUtilsPostCards.vue")
		},
		props: {
			data: {
				type: Object,
			    default: () => {},
			},
		},
		data() {
			return {
				cards: [],
				page: 1,
				limit: 4,
			}
		},
		mounted() {
			console.log(this)
		},
	}
</script>

<style lang="scss" scoped>
.header-content {
    text-align: center;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    .cs-title, .cs-sub-title {
        margin-bottom: 20px;
    }
    .cs-text {
        ::v-deep {
            * {
                text-align: center;
            }
            img {
                max-width: 100%;
                height: 300px;
                border-radius: 10px;
                margin: 10px;
            }
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
